
const utils = {
  frameworkName() {
    const nameSpace = [ 'ibAds', 'mdscapeAds' ].filter(n => {
      return n in window !== undefined;
    })[0];
    return nameSpace;
  },
  returnHostMessage(data) {
    let host = window.location.host;
    let vendorHost = host.split('.').splice(-2)[0];
    let postData = {
      host: vendorHost
    };
    let count = 0; // return message getting sent multiple times, possibly due to creative loading?
    if (data.returnMessage && count < 1) {
      let ad = document.querySelector(`#${data.slotId}`);
      let iframe = ad.querySelector('iframe');
      if (iframe && iframe.contentWindow) {
        // temporarily add style tag, linked sheet will need to be added by Univadis devs
        let styleSheet = document.createElement('style');
        styleSheet.textContent = `.sidebar [data-ad-pos="${ad.dataset.adPos}"] { width: ${data.width}px; }`;
        document.body.appendChild(styleSheet);
        iframe.contentWindow.postMessage(JSON.stringify(postData), '*');
        if (data.iFrameHeight) iframe.setAttribute('height', iFrameHeight);
        count = 1;
      }
    }
    return postData;
  },
  getHash() {
    return (location.hash === '') ? false : location.hash.substr(location.hash.indexOf('#') + 1);
  },
  checkQueryString(url, pos, src = "layer") {
    let site = '_';
    let domain = location.hostname;

    site += [
      'mdedge.com',
      'univadis.de',
      'univadis.fr',
      'univadis.es',
      'univadis.it',
      'medscape.co.uk',
      'jim.fr',
      'coliquio',
    ].filter(d => domain.indexOf(d) != -1)[0];

    const splitFunc = (_url) => {
      let arr1 = _url.split('%3F');
      let srcString = `src=${src}_${pos + site}`;
      if (_url.length > 1 && arr1[1] != undefined && arr1[1] != '' && (arr1[1].indexOf("%3D") != -1)) {
        url = url + '&' + srcString;
      } else if (_url.length > 1) {
        url = url + '?' + srcString;
      }
    }

    if (url.indexOf('adurl=') != -1) {
      let url1 = url.split('adurl=')[1];
      if (url1 !== '') splitFunc(url.split('adurl=')[1]);
    } else if (url.indexOf('adurl=') == -1) {
      if (url !== '') splitFunc(url);
    }

    return url;
  },
  /**
     * Get the current target's container
     * @param idPrefix string id of the container
     * @param buildObject object object that represents the build (medscape, ads, etc)
     *
     * Example,
     * getTargetContainer('content_', profads.ads2);
     */
  getTargetContainer(idPrefix, buildObject) {
    const hash = this.getHash();
    let selector = '';
    if (hash) {
      selector = `#${idPrefix}${hash.substr(hash.indexOf('#') + 1)}`;
    } else {
      selector = `[id^="${idPrefix}"]`;
    }
    buildObject.currentAdTarget = document.querySelectorAll(selector)[0];
    return buildObject.currentAdTarget;
  },
  getVisibleAd(idPrefix, buildObject, className) {
    return this.getTargetContainer(idPrefix, buildObject).find(`.${className}`);
  },
  getAffiliateDomain() {
    const affiliateList = [ 'univadis', 'medscape', 'mdalert', 'powerpak', 'mdedge', 'uspharmacist', '2020mag', 'jim' ].filter(a => {
      return window.location.host.indexOf(a) !== -1;
    });
    if (affiliateList[0] === 'medscape') affiliateList[0] = 'medscapeUK';
    if (affiliateList[0] === '2020mag') affiliateList[0] = 'jobson';

    return affiliateList[0];
  },
  createElement(nodeConfig) {
    function nodeIterator(obj) {
      const node = document.createElement(obj.name);
      if (obj.id) node.id = obj.id;
      if (obj.className) node.className = obj.className;
      if (obj.text) {
        if (obj.renderHTML) {
          // if obj.text is a type of element node, append as child to node
          if (typeof obj.text === 'object' && obj.nodeType !== 'undefined') node.appendChild(obj.text);
          else node.innerHTML = obj.text;
        } else node.textContent = obj.text;
      }
      if (obj.type) node.type = obj.type;
      if (obj.attributes) {
        // expects and array of objects
        obj.attributes.forEach((attr) => {
          let key = typeof attr === 'object' ? Object.keys(attr)[0] : attr;
          // test for attributes without a value, like boolean which default to true
          let val = attr[key] || '';
          node.setAttribute(key, val);
        });
      }
      return node;
    }

    let newNode = nodeIterator(nodeConfig);
    // TODO add abilitiy to loop through nested child elements in AppHelpers.createElement
    function childrenNodeLoop(obj) {
      obj.forEach((child) => {
        // recursive loop for nested children
        if (child.children) {
          let parent = nodeIterator(child);
          child.children.forEach((sub) => {
            if (sub.children) {
              let sub_parent = nodeIterator(sub);
              sub.children.forEach(n_sub => { sub_parent.appendChild(nodeIterator(n_sub)) });
              parent.appendChild(sub_parent);
            } else {
              parent.appendChild(nodeIterator(sub));
            }
            newNode.appendChild(parent);
          });
        } else {
          newNode.appendChild(nodeIterator(child));
        }
      });
    }

    if (nodeConfig.children) childrenNodeLoop(nodeConfig.children);
    if (nodeConfig.wrap) newNode = wrap(newNode);
    if (nodeConfig.eventHandler) {
      try {
        newNode.addEventListener(nodeConfig.eventHandler.eventName, nodeConfig.eventHandler.fn);
      } catch (err) {
        console.error('not able to add event listener to node', newNode, err);
      }
    }

    // wrap an single element, for nodes without multiple child elements
    function wrap(node) {
      let wrapper = nodeIterator(nodeConfig.wrapWith);
      return wrapper.appendChild(node);
    }

    // create child nodes from array in the config
    return newNode;
  },
  createEvent(eventName, config) {
    return new CustomEvent(eventName, {
      detail: config
    });
  },
  appendExternalNode(content, container) {
    container = typeof container !== undefined ? container : document.body;
    // if dom hasn't finished rendering, container will still be undefined => wrap in event
    if (container === undefined) {
      document.addEventListener('DOMContentLoaded', () => {
        document.body.appendChild(content);
      });
    } else {
      container.appendChild(content);
    }
    return container;
  },
  closeAppendedNode(nodeId) {
    let element = document.getElementById(nodeId);
    element.parentNode.removeChild(element);
  },
  isEmpty(el) {
    return !(el.innerHTML.trim());
  },
  getScreenOrientation() {
    return (window.outerWidth > window.outerHeight) ? 'landscape' : 'portrait';
  },
  loadScript(config) { // settings object properties: url, onLoadHandler, cors
    const script = document.createElement('script');
    const parent = config.placement ? config.placement : document.head;
    script.setAttribute('src', config.src);
    script.setAttribute('type', 'text/javascript');
    if (config.id) script.setAttribute('id', config.id);
    if (config.async) script.setAttribute('async', '');
    if (config.cors) script.setAttribute('crossorigin', '');
    if (config.extraAtributes) config.extraAtributes.forEach(x => {
      script.setAttribute(Object.keys(x)[0], Object.values(x)[0]);
    });

    parent.insertBefore(script, null);
    if (config.loadHandler) {
      script.onload = config.loadHandler;
    } else if (config.handler) {
      script.onload = config.handler;
    }
    if (config.errorHandler) script.onerror = config.errorHandler;
  },
  isParent(posArray, id) {
    // test that posArray only contains numbers !isNaN()
    const validPos = posArray.every(val => !isNaN(parseInt(val)));

    if (!validPos) throw Error('you must provide an array with numbers as the pos value.');
    // test whether the id is contained in posArray, first convert id into a number
    // pos is made from the commonly used id format, ex: 'ads-pos-1145'
    let pos = parseInt(id.split('-').pop());
    return posArray.indexOf(pos) !== -1;
  },
  isElementVisible: function(elementToBeChecked) {
    const TopView = $(window).scrollTop();
    const BotView = TopView + $(window).height();
    const TopElement = $(elementToBeChecked).offset().top;
    const BotElement = TopElement + $(elementToBeChecked).height();
    return ((BotElement <= BotView) && (TopElement >= TopView));
  },
  adCollideTest: function(adTopPos, topVal) {
    if (adTopPos.top < topVal) {
      return Boolean(true);
    } else {
      return Boolean(false);
    }
  },
  getParam: function(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  },
  breakPoints: [
    { bp1: '(min-width: 320px) and (max-width: 767px)' },
    { bp2: '(min-width:768px) and (max-width: 1023px)' },
    { bp3: '(min-width:1024px) and (max-width: 1399px)' },
    { bp4: '(min-width: 1400px)' }
  ],
  getBrkPnt(getNumber = true) {
    // if getNumber is set to true, return a number object, if not return key (string value)
    const bp = this.breakPoints.filter(query => {
      return window.matchMedia(Object.values(query)[0]).matches;
    })[0];
    let key = Object.keys(bp)[0];
    if (getNumber) {
      return key !== undefined ? parseInt(key.substr(key.length - 1)) : 1;
    } else {
      return key !== undefined ? key : undefined;
    }
  },
  getUrlVars() {
    let vars = {};
    let parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
      vars[key] = value;
    });
    return vars;
  },
  GenPVID() {
    // getting the date, getting the seconds from epoch
    const timestamp = new Date().getTime() / 1000;
    //Rounding the decimal off
    const epochSec = Math.round(timestamp).toString();
    // making an 8 digit random number
    const randomNumber = Math.random().toString().substr(2, 8);
    // set the global variable to the 19 digit page view id
    const tempPVIDreturn = epochSec + randomNumber;
    document.dispatchEvent(new CustomEvent('pvidReset'));
    return tempPVIDreturn;
  },
  sanitizeURI(uri) {
    return uri.replace(/[ ( | )| ? | & | # | = | +]/gmi, '');
  },
  getEnvironment() {
    let env = webmd.ads2.pageTargets.envp;
    if (env == "undefined") env = "";
    let prod = "prodma1";

    if (env === 'dev02') {
      env = 'dev01';
    } else if (env == 'qa') {
      env = 'qa00';
    } else if (env == 'qa01') {
      env = 'qa01';
    } else if (env == 'stg' || env == 'staging') {
      env = 'staging';
    } else if (env == 'prod') {
      prod = 'prod';
    }

    return env == prod ? "" : `.${env}`;
  },
  setRequestAnimationFrame() {
    return window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            window.oRequestAnimationFrame;
  },
  checkConditionRepeat(condition, time = 2000) {
    let start, myReq;
    const maxTime = time;
    const requestAnimationFrame = this.setRequestAnimationFrame();
    // if condition is not a param, return
    if (condition === undefined) return;

    return new Promise((resolve, reject) => {
      function step(timestamp) {
        let _condition = typeof condition === 'function' ? condition() : condition;
        if (start === undefined) start = timestamp;

        const elapsed = timestamp - start;

        if (_condition) {
          cancelAnimationFrame(myReq);
          resolve(_condition);
        }

        if (elapsed < maxTime) { // Stop the animation after 2 seconds
          myReq = requestAnimationFrame(step);
        } else if (elapsed >= maxTime) {
          reject('max time reached to check condition');
          cancelAnimationFrame(myReq);
        }
      }
      myReq = requestAnimationFrame(step);
    });
  },
  elementInView(ele, percent = 100) {
    if (!ele) {
      console.error('ele parameter is missing or not a DOM element!');
      return;
    }
    const winHeight = window.innerHeight;
    const rect = ele.getBoundingClientRect();
    let amount, condition = (rect.top >= 0 && rect.bottom <= winHeight);

    if (percent < 100) {
      amount = rect.height * parseFloat(`.${percent}`);
      condition = (rect.top >= amount && rect.top < winHeight) || (rect.bottom >= amount && rect.bottom < winHeight);
    }

    return condition;
  },
  trigger(el, name, payload) {
    let event = this.createEvent(name, payload);
    el.dispatchEvent(event);
  },
  // make alias point to hideAdLabel(), the function name should correspond to it's return value and or internal logic
  getAdParentElement(data) {
    let adContainer;
    let adSlotId = data.slotId;
    //Variable to reference  the frame element.
    if (adSlotId !== undefined) {
      adContainer = document.getElementById(adSlotId);
    }
    else if (data.adpos !== undefined) {
      adContainer = document.getElementById('ads-pos-' + data.pos);
    }
    else {
      let adFrameElement = window.parent.document.getElementById(data.id);
      // recent error 7/27/20  data does not have a slotId, id, or adpos. Possibly due to some templates not having updated script
      if (adContainer == null) return;
      adContainer = window.parent.document.getElementById(adFrameElement.parentNode.parentNode.id);
    }
    if (adContainer !== null && data.toggleLabel === undefined) {
      //Regex expression to match the ad classes.
      let resetRegex = /adlabelifi|adlabelifg|adlabelblank|adlabelclq/gi;
      //Reset adClass. Remove ad label classes, and trim white space.
      let adClass = adContainer.className.replace(resetRegex, '').trim();
      //Reset class names on the ad container.
      adContainer.className = adClass;
      let adLabel = data.adLabel.toLowerCase();

      this.utils.toggleAdLabel(adLabel, adContainer, adClass);
    }
    return adContainer;
  },
  toggleAdLabel(label, ad, adClass) {
    if (label == "ifi") {
      ad.className = `${adClass} adlabelifi`;
    } else if (label == "ifg") {
      ad.className = `${adClass} adlabelifg`;
    } else if (label == "blank") {
      ad.className = `${adClass} adlabelblank`;
    } else if (label == "adlabelclq") {
      ad.className = `${adClass} adlabelclq`;
    }
    return ad.className;
  },
  nativeGraphic(data) {
    if (data.template == "nativegraphic728x90") {
      document.getElementById(data.slotId).style.minWidth = "728px";
    }
    if (data.template == "nativegraphic300x50") {
      document.getElementById(data.slotId).style.minWidth = "300px";
      if (document.documentElement.clientWidth == "320") {
        document.getElementsByClassName("ad-rectangle-top")[0].style.width = "300px"
      }
    }
  },
  postOmnitureData(data) {
    if (typeof s_md !== 'object' && typeof wmdPageLink !== 'function') return;
    if (typeof data.textLink !== "undefined") {
      s_md.contextData['wb.lnktxt'] = data.textLink;
    }
    wmdPageLink(data.mmodule + "_" + data.mlink);

    if (data.callBack) data.callBack();
    if (typeof data.textLink !== "undefined") {
      s_md.contextData['wb.lnktxt'] = "";
    }
  },
  isEmptyObject(val) {
    if (typeof val !== 'object') return false;
    if (Object.keys(val).length === 0) return false;
  },
  getCookie: function(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  },
  loadCSS(data) {
    let path = `//img${this.getEnvironment()}.medscapestatic.com/medscape-core/ads/css/${data}.css`;
    let css = this.createElement({
      name: 'link',
      attributes: [
        { rel: 'stylesheet' },
        { href: path },
        { type: 'text/css' },
        { media: 'screen' }
      ],
      eventHandler: {
        eventName: 'load',
        fn() {
          console.log('loaded: ', data);
        }
      }
    });
    this.appendExternalNode(css, document.head);
    return css;
  },
  getDomainName() {
    let site = '.';
    let domain = location.hostname;
    return site += [
      'mdedge.com',
      'univadis.de',
      'univadis.fr',
      'univadis.es',
      'univadis.it',
      'medscape.co.uk',
      'jim.fr',
      'coliqu.io',
      'coliquio.de',
    ].filter(d => domain.indexOf(d) != -1)[0];
  },
  // Set PIMC
  setPIMC(value = 0, ppidExpired = false) {
    const pmicCookie = (document.cookie.match(/(?:^|;\s*)PIMC=([^;]*)/) || [])[1] || null;
    if (pmicCookie == null || ppidExpired) {
      webmd.ads2.setPageTarget('pimc', 0);
      const expiryDate = new Date(Date.now() + 30 * 60 * 1000);
      document.cookie = `PIMC=${value}; expires=${expiryDate.toUTCString()}; domain=${ibAds.utils.getDomainName()}; path=/; secure`;
      document.cookie = `EXPIRY_TIME=${expiryDate.toUTCString()}; expires=${expiryDate.toUTCString()}; domain=${ibAds.utils.getDomainName()}; path=/; secure`;
    }
    else {
      webmd.ads2.setPageTarget('pimc', pmicCookie);
    }
  },
  // Update PIMC
  updatePIMC() {
    const pmicCookie = (document.cookie.match(/(?:^|;\s*)PIMC=([^;]*)/) || [])[1] || null;
    if (pmicCookie == null) {
      this.setPIMC(1);
      return;
    }
    const currentValueInt = !isNaN(pmicCookie) ? parseInt(pmicCookie, 10) : 0;
    const newPmicValue = currentValueInt + 1;
    const expiryCookie = (document.cookie.match(/(?:^|;\s*)EXPIRY_TIME=([^;]*)/) || [])[1] || null;
    if (expiryCookie) {document.cookie = `PIMC=${newPmicValue}; expires=${expiryCookie}; domain=${ibAds.utils.getDomainName()}; path=/; secure`;}
    else {this.setPIMC(1);}
    console.log(`PIMC value in local build is: ${newPmicValue}`);
  },
  GDPR_optin_check() {
    return document.cookie.match(/OptanonConsent[^;]+&groups[^;]+C0004%3A1/) != null;
  }
};

export default utils;

// alias of object method for modules that don't need entire utils object
export function getFramework() {
  return utils.frameworkName();
}


