export default {
  name: "browsi",
  init() {
    let browsiSites = [
      "medscape.co.uk",
      "univadis.de",
      "univadis.es",
      "univadis.fr",
      "univadis.it",
      "jim.fr",
      "mdedge.com",
      "coliquio.de",
      "coliquio.io"
    ];
    browsiSites.forEach((v, i) => {
      if (location.host.indexOf(v) !== -1) {
        function loadScript(tagSrc) {
          var scriptTag = document.createElement("script"),
            placeTag = document.getElementsByTagName("script")[0];
          scriptTag.type = "text/javascript";
          scriptTag.async = true;
          scriptTag.charset = "UTF-8";
          scriptTag.id = "browsi-tag";
          scriptTag.setAttribute("data-pubkey", "webmd");
          scriptTag.setAttribute("data-sitekey", "d_mapping");
          scriptTag.src = tagSrc;
          placeTag.parentNode.insertBefore(scriptTag, placeTag);
        }
        var mnSrc = "https://cdn.browsiprod.com/bootstrap/bootstrap.js?e=1";
        loadScript(mnSrc);
      }
    });
  },
};

