function postRecricWidgetSetUp(data) {
  let _adContainer = document.getElementById(data.slotId);
  _adContainer.classList.add("recirc-container");
  if (pageCurrentURL.indexOf("coliqu") !== -1) {
    Object.assign(_adContainer.style, { borderBottom: "none" });
  } else {
    Object.assign(_adContainer.style, {
      borderBottom: "1px solid rgba(151,151,151,0.3)",
    });
  }

  //top-pick adding logic
  var element = document.getElementById("top-pick");
  if (element == null) {
    var el = document.createElement("div");
    Object.assign(el.style, {
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: "700",
      paddingBottom: "15px",
      borderBottom: "1px solid rgba(151,151,151,0.3)",
    });
    if (pageCurrentURL.indexOf("jim.fr") !== -1) {
      el.textContent = "MEILLEURS ARTICLES POUR VOUS";
      Object.assign(el.style, { color: "#313131" });
    } else if (pageCurrentURL.indexOf("coliqu") !== -1) {
      el.textContent = "Für Sie aus Medscape ausgewählt";
      Object.assign(el.style, {
        color: "#333333",
        textAlign: "left",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        borderBottom: "none",
        paddingBottom: "8px",
        paddingRight: "24px",
      });
    } else el.textContent = "TOP PICKS FOR YOU";
    el.setAttribute("id", "top-pick");
    if (ibAds.utils.getBrkPnt() == 1) {
      if (pageCurrentURL.indexOf("jim.fr") !== -1)
        Object.assign(el.style, { fontSize: "18px" });
      else Object.assign(el.style, { fontSize: "16px" });
      if (pageCurrentURL.indexOf("univadis") !== -1) {
        Object.assign(el.style, { paddingBottom: "12px" });
      }
    }
    var parentContainer = document.getElementsByClassName("tpfy-container")[0]
      ? document.getElementsByClassName("tpfy-container")[0]
      : document.getElementsByClassName("tpfy_container")[0]; //Univadis site has tpfy_container;
    if (parentContainer) {
      parentContainer.insertBefore(el, parentContainer.children[0]);
    }
  }
}

export default postRecricWidgetSetUp;

