import utils from './common/utility.js';
import SetProfSizes from './common/SetProfSizes';
import wbmdObject from './common/webmdObject';
import Webmd from './common/webmdAds2.js';
import medianet from './third-parties/medianet';
import doubleVerify from './third-parties/doubleVerify';
import browsi from './third-parties/browsi';
import meads from './third-parties/meads';
import ibadsStaticTargetKeys from './common/IbadsStaticTargetKeys.js';
import ibadsDynamicTargetKeys from './common/IbadsDynamicTargetKeys.js';
import postRecricWidgetSetUp from './common/ibads-post-recirc-widget.js';


window.ibAds = window.ibAds || {};
window.webmd = window.webmd || {};
webmd.object = wbmdObject;
webmd.ads2 = new Webmd();

// initialize the webmd.tpsvc namespace
webmd.tpsvc = webmd.tpsvc || {};

// initialize the webmd.tps command queue - a queue for storing third party service commands
webmd.tpsvc.cmds = webmd.tpsvc.cmds || [];

// if the 3rd-party service implements a promise, initialize the webmd.tpsvc promise queue
// the promise is a placeholder into which response from the 3rd-party service is inserted.
webmd.tpsvc.promises = webmd.tpsvc.promises || [];

// Meads ipon global variables
window.oldPositions = {};
window.oldMEpos = [];
window.enableME = true;
//end of Meads

const referenceKeys = [ 'pos', 'usp', 'ssp', 'env', 'pub', 'art', 'cg', 'dmp', 'mapp', 'kw', 'pc', 'sec', 'spon', 'tar', 'tc', 'll', 'uri', 'auth', 'pdi', 'ct', 'pdp', 'asid', 'pds', 'occ', 'pf', 'pbr', 'scg', 'st', 'ppub', 'acb', 'mnrf', 'medcase', 'sname', 'medsec', 'mniv', 'rord', 'imid', 'mop', 'mcs', 'pvid', 'moa', 'auu', 'val', 'mob', 'oaw', 'envp', 'pai', 'pae', 'usoo', 'ep', 'path3', 'sa', 'path2', 'dt', 'ac', 'as', 'al', 'rd', 'isValid', 'pb', 'ck', 'usps', 'ad_slot', 'path1', 'pg', 'bp', 'gd', 'pbs', 'lif', 'ecd', 'vp', 'fpt', 'acs', 'pvb', 'cn', 'pgr', 'mnetNative', 'dis', 'actid', 'aamid', 'masid', 'mnetUGD', 'mnetCV', 'vit', 'pbp', 'stick', 'xgdp', 'iaf', 'm_data', 'm_safety', 'm_categories', 'm_mv', 'm_gv', 'oid', 'mnetPageID', 'mnetCC', 'mnetCID', 'mnetDNB', 'ctype', 'geoc', 'tug' ].map(k=>k.toLowerCase());
const unMatchedKeys = [];

document.dispatchEvent(new CustomEvent('LibReady', { detail: 'ads' }));

document.addEventListener('DFP_DISPLAY_CALLED', () => {
  document.addEventListener('pvidReset', (e) => {
    if (window.s_pageview_id && webmd && webmd.ads2 && webmd.ads2.refresh) {
      if (e && e.detail !== true) {
        console.log("PVID pvidReset");
        webmd.ads2.refresh({ keys: { pvid: s_pageview_id } });
      }
    }
  });
});


document.addEventListener('userIDComplete', function(data) {
  window.readyPulsePoint = true;
  ibAds.init();
});

// Variable for the Current Page URL as we use this data many times
// is references in the collapsead script
window.pageCurrentURL = window.location.href.toLowerCase();

window.profads = {};
window.ibAds = {
  init() {
    if (!window.inItOnce) {
      window.inItOnce = true;
      if (typeof window.PageMetadata == 'undefined' && typeof window.DFPTargetKeys == 'object') {
        window.PageMetadata = window.DFPTargetKeys;
      } else if (typeof window.PageMetadata == 'object' && typeof window.DFPTargetKeys == 'object') {
        window.PageMetadata = Object.assign({}, window.DFPTargetKeys, window.PageMetadata);
      }
      profads.ads2 = ibAds;
      const self = this;
      self.utils = utils;
      self.frameworkName = self.utils.frameworkName().toLowerCase();

      // *** check if affiliate sizes are provided, if not use default size object ***
      SetProfSizes();
      // init was already called
      if (self.init.flag) {
        console.warn("Init was already called");
        return;
      }
      self.thirdPartyModules = [ doubleVerify, browsi ];
      // medscape exchange only implemented for mdedge
      if (location.hostname.indexOf("mdedge.com") !== -1) {
        self.thirdPartyModules.push(meads);
      }
      self.allowedMediaNet = [ 'medscape.co.uk', 'univadis.de', 'univadis.es', 'univadis.fr', 'univadis.it', 'mdedge.com', 'jim.fr', 'coliqu.io', 'coliquio.de' ];
      self.allowedMediaNet.forEach((v, i) => {
        if (location.host.indexOf(v) !== -1) self.thirdPartyModules.push(medianet);
      })
      self.init.flag = true;
      self.makeAliases();
      self.setAdIgnore(); // set ads to ignore
      self.load3rdPartyModules();
      self.initializeWebmdAds(); // initialize webmd
      self.isMobile = self.utils.getBrkPnt() === 'bp1';
      if (PageMetadata.userSegVars && PageMetadata.userSegVars.pbr) {
        self.pbr = (Array.isArray(PageMetadata.userSegVars.pbr) || typeof PageMetadata.userSegVars.pbr == 'string') ? PageMetadata.userSegVars.pbr.slice() : null;
      } else {
        self.pbr = null;
      }
      // self.pbr = (PageMetadata.userSegVars && PageMetadata.userSegVars.pbr) ? PageMetadata.userSegVars.pbr.slice() : null;
      self.spbr = {};
      // procASID will store new ASID returned from proclivity API calls
      self.procASID = [];
      self.hostSafeList = [ 'medscape', 'googlesyndication', 'mdedge', 'univadis', 'mdalert', 'coliquio', 'jim' ];
      // alias function names from Medscape
      self.mutationTargets = [];
      self.addEventListeners.call(this);
      webmd.ads2.googleDefer(function() {
        if (location.href.indexOf('mdalert') !== -1) {
          webmd.ads2.setPageTarget('saf', self.utils.getCookie('ads_saf') ||  0);
        }
      });
    }

    if (typeof window.libLoadStatus == 'undefined' || window.libLoadStatus.complete) {
      let scope = window.ibAds;
      window.PageMetadata = Object.assign({}, window.DFPTargetKeys, window.PageMetadata);
      if (document.readyState === 'interactive' || document.readyState === 'complete') {
        scope.makeAdCall();
      } else {
        document.addEventListener('readystatechange', (e) => {
          if (document.readyState === 'complete') scope.makeAdCall();
        });
      }
    }
  },
  makeAliases() {
    this.getIframeParentElement = this.utils.getAdParentElement;
    this.aggressiveDriver = this.insertLayerAd;
    this.returnHostMessage = this.utils.returnHostMessage;
    this.nativeGraphic = this.utils.nativeGraphic;
    this.postOmnitureData = this.utils.postOmnitureData;
    this.setPIMC = this.utils.setPIMC;
    this.updatePIMC = this.utils.updatePIMC;
    this.postRecricWidgetSetUp = postRecricWidgetSetUp;
  },
  initializeWebmdAds() {
    webmd.ads2.init();
    webmd.ads2.slotRenderEnded();
  },
  getLineItemId(data) {
    var lid = data.lid;
    if (sessionStorage.getItem('ibadsLif') == null && data.lid !== null) {
      sessionStorage.setItem('ibadsLif', lid);
      webmd.ads2.setPageTarget('lif', lid);
    }
  },
  removePinlines: function(data) {
    const posDiv = data.slotId;
    const posEle = document.getElementById(posDiv);
    if (posEle) {
      posEle.style.borderTop = "none";
      posEle.style.borderBottom = "none";
    }
  },
  messageHandler(event) {
    const self = this;
    function isSafe() {
      return self.hostSafeList.filter((host) => {
        return event.origin.indexOf(host) !== -1;
      }).length > 0;
    }
    function getFunction(arg) {
      return Function('return ' + arg);
    }
    // only allow postMessages from white listed domains with a valid callback function
    if (isSafe()) {
      try {
        const data = typeof event.data === 'string' ? JSON.parse(event.data) : (typeof event.data === 'object' ? event.data : { event });

        if (data.callBack) {
          if (typeof this[data.callBack] === 'function') {
            this[data.callBack](data, event.origin)
          } else if (data.callBack.split('.').length > 1) {
            let method = getFunction(data.callBack);
            if (typeof method === 'function') method()(data, event.source);
          } else {
            console.warn('callBack not found', data.callBack);
          }
        }
      } catch (e) {
        console.warn('unable to parse json: ', e);
      }
    }
  },
  addEventListeners() {
    const self = this;
    // listen to the 'collapse' event from collapse-ad.js
    window.addEventListener('collapse', (e) => {
      var adPosDiv = document.getElementById(e.detail.parent);
      if (adPosDiv === null) {
        return adPosDiv;
      } else {
        var wrapperClass = 'ad-wrapper';
        var wrapper = document.getElementById(e.detail.parent);
        var parentCount = 3;
        for (var i = 0; i <= 3; i++) {
          if (typeof wrapper.classList === 'undefined') {
            break;
          } else if (wrapper.classList.contains(wrapperClass)) {
            break;
          } else {
            wrapper = wrapper.parentNode;
          }
        }
        var collapseDiv = adPosDiv;
        if (typeof wrapper.classList !== 'undefined' && wrapper.classList.contains(wrapperClass)) {
          collapseDiv = wrapper;
        }
        collapseDiv.style.display = 'none';

        //conditional for browsiAd label collapser
        if (e.detail.parent.indexOf('browsi') > -1) {
          var browsiDiv = document.getElementById(e.detail.parent);
          for (var i = 0 ; i <= 3 ; i++) {
            if (browsiDiv.id.includes("browsi_adWrapper") || browsiDiv.id.includes("browsi_wrapper")) {
              break;
            } else {
              browsiDiv = browsiDiv.parentNode;
            }
          }
          browsiDiv.style.display = "none";
        }
        return collapseDiv;
      }
    }, false);

    window.addEventListener('alters-complete', () => {
      // this may be triggered before ibads is initialized. It indicates when slotKeys are available.
      self.altersComplete = true;
    });
    window.addEventListener('message', self.messageHandler.bind(self));
    window.addEventListener('ibads:ready', () => { // event is triggered by makeAdCall()
      self.initializeWebmdAds(); // initialize webmd
      self.affiliateName = self.utils.getAffiliateDomain();
      if (self.affiliateName) document.body.classList.add(`affiliate-${self.affiliateName}`); // add body class with affiliate name for CSS use
    })
    //addEventListener textAd
    window.addEventListener('textAdRender', (e) => {
      const adTextAdDiv = document.getElementById(e.detail.iframe).parentNode.parentNode;
      adTextAdDiv.classList.add('textAdClass');
    }, false);
    window.addEventListener('displayAdhesiveFooter', (e)=>{
      self.displayAdhesiveFooter(e.detail);
    });
  },
  addMutationListener(config) {
    // for proof of concept only, but could also be used to collapse ad
    return;

    const mutateConfig = Object.assign({
      attributes: true,
      childList: true,
      subtree: true
    }, config.options);

    const callback = (mutationsList) => {
      for (let mutation of mutationsList) {
        switch (mutation.type) {
        case 'childList':
          // perform action on element
          break;
        case 'attributes':
          if (config.callBack && typeof config.callBack === 'function') config.callBack.call(null, mutation.target);
          break;
        }
      }
    }

    const observer = new MutationObserver(callback);
    this.mutationTargets.push({ ele: config.target, mObserver: observer });
    observer.observe(config.target, mutateConfig);
    // Later, you can stop observing
    //observer.disconnect();
  },
  callBacks: {
    hasBlankAd(target) {
      console.log('%c mutation target sent to hasBlankAd()', 'background: #C63607; color: #fff;', target.classList.contains('blank-ad'), target.id);
    }
  },
  insertParallax(e) {
    const that = this;
    that.utils.loadScript({
      src: `//img${that.utils.getEnvironment()}.medscapestatic.com/medscape-core/ads/js/dfp/external/parallax.js`,
      loadHandler() {
        profads.ads2.initParallax(e);
      },
      errorHandler() { console.error('parallax script failed to load.') },
      async: true,
      cors: true
    });
    return that;
  },
  insertAdhesiveVideo(e) {
    const that = this;
    that.utils.loadScript({
      src: `//img${that.utils.getEnvironment()}.medscapestatic.com/medscape-core/ads/js/dfp/external/ibads-adhesive-video.js`,
      loadHandler() { that.initAdhesiveVideo(e) },
      errorHandler() { console.error('Adhesive video script failed to load.') },
      async: true
    });
  },
  insertLayerAd(data) {
    const that = this;
    let config = data.detail === undefined ? data : data.detail;
    let delay = config.delay || 0;
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(config);
        if (!config) reject('event config not defined');
      }, delay);
    }).then(config => {
      // the new name of the cpc layer is NativeConnect, so we want to map the names for future use
      const layerType = [
        { 'LLR': 'LLR' },
        { 'layer-cpc': 'NativeConnect' },
        { 'native-footer': 'NativeFooter' },
        { 'toaster': 'Ibads-Toaster' },
        { 'promo-layer': 'PromoLayer' }
      ].filter(l => Object.keys(l)[0] === config.productType)[0];

      const layerName = Object.values(layerType)[0];
      let fileName = `${layerName}.js`.toLowerCase();
      config.layerType = layerName;
      //To fix layer issue in ibads ibads-nativeconnect.js needs to used
      if (config.productType == "layer-cpc") {
        fileName = "ibads-" + fileName;
      }
      if (config.productType === 'native-footer') fileName = "ibads-" + fileName;

      // do not load more than once
      if (typeof ibAds[layerName] === 'function') return;

      this.utils.loadScript({
        src: `//img${that.utils.getEnvironment()}.medscapestatic.com/medscape-core/ads/js/dfp/layerads/${fileName}`,
        loadHandler() {
          if (typeof ibAds[layerName] !== 'function') return;
          // this if() refers to the Class name declared in the file for the class. Example: frameWork['NativeConnect'] = class NativeConnect extends LayerAd
          const NativeAdCLass = ibAds[layerName].name + 'LayerAd';
          //ibAds[layerName] is the class extended from the LayerAd class
          //ibAds[NativeAdCLass] is an instance of that class
          ibAds[NativeAdCLass] = new ibAds[layerName](config);
          ibAds[NativeAdCLass].render();
        },
        errorHandler(err) { console.error('Layer ad script failed to load.', err) },
        async: true
      });
    }).catch(err => { console.error('insertLayerAd error: ', err) });

    window.addEventListener('layerLoaded event: ', (e) => { console.log('on layerLoaded', e.detail) });
  },
  postCpData(cpdata, Cpcallback) {
    if (pageCurrentURL.indexOf('medscape.com') !== -1) {
      delete cpdata.callBack;
      cpdata.uid = window.s_registered_user_id || '';

      if (typeof cpdata.url == 'undefined') {
        cpdata.url = encodeURIComponent(window.location.href.split("?")[0]);
      }
      cpdata.date = new Date().getTime();
      let cpJSON = JSON.stringify(cpdata);
      const ajaxUrlPath = `//api${this.utils.getEnvironment()}.medscape.com/cp/events.json`;

      function jsonp(url, url2, callbackCp) {
        var callbackName = 'jsonp_callback_' + Math.round(100000 * Math.random());
        window[callbackName] = function(data) {
          delete window[callbackName];
          document.body.removeChild(script);
          if (typeof callbackCp == 'function') {
            callbackCp(data);
          }
        };
        var script = document.createElement('script');
        script.src = url + (url.indexOf('?') >= 0 ? '&' : '?') + 'callback=' + callbackName + url2;
        document.body.appendChild(script);
      }
      jsonp("https:" + ajaxUrlPath, "&event=" + cpJSON, function() {
        if (typeof Cpcallback != 'undefined' && typeof Cpcallback == 'function') {
          Cpcallback();
        }
      });
    }
  },
  setAdIgnore() {
    window.ads2_ignore = window.ads2_ignore || {};
    /*
        const platform = ibAds.utils.getBrkPnt(true) > 1 ? 'desktop' : 'mobile';
        const posLength = platform === 'desktop' ? 3 : 4;
        let exceptionList = ['910']; // pos applies to desktop & mobile, if index of 'all' apply to mob & dt
        const affiliate = this.utils.getAffiliateDomain();

        switch (affiliate) {
            case 'univadis':
                exceptionList.splice(exceptionList.length, 0, '904', '906', '907', '916', '917', '1004', '1122', '1420', '1145', '1520');
                break;
            case 'medscapeUK':
                exceptionList.splice(exceptionList.length, 0, '420', '520', '904', '906', '907', '916', '917');
                break;
            case 'mdalert':
                // code
                break;
            case 'mdedge':
                exceptionList.push('all');
                break;
            case 'jobson':
                // code
                break;
            case 'uspharmacist':
                // code
                break;
            case 'powerpak':
                // code
                break;
            default :
                // code
        }

        Object.keys(webmd.ads2.sizes).forEach(k => {
           if(k.length !== posLength && exceptionList.indexOf(k) === -1 && exceptionList.indexOf('all') === -1) {
                window.ads2_ignore[k] = "true";
            }
        });
        */
  },
  makeAdCall() {
    window.dispatchEvent(new CustomEvent('ibads:ready'));
    const self = this;
    if (self.makeAdCall.flag) {
      console.warn("makeAdCall was already called");
      return;
    }
    self.makeAdCall.flag = true;

    // Isn't doing anything yet, no exclusion or target keys to set
    self.setExcList();
    //check consent and set PIMC and PPID from cookie
    if (document.querySelector('script[src*="otSDKStub.js"]') == null || document.cookie.match(/OptanonConsent[^;]+&groups[^;]+C0004%3A1/) != null || (document.querySelector('script[src*="otCCPAiab.js"]') != null && document.cookie.match(/OptanonConsent[^;]+&groups/) == null)) {
      try {
        self.utils.setPIMC();
      } catch (e) {
        console.log("Error occured while setting PIMC");
      }
      ibAds.getPPID();
    }
    //self.setTargetKeys();  sets page level targeting
    webmd.ads2.setPageTarget(self.CapTargetKeys());
    ibadsStaticTargetKeys();
    ibadsDynamicTargetKeys();
    self.adDisplay();
  },
  CapTargetKeys: function(TKeysObj) {
    // set pvid val as global
    TKeysObj = TKeysObj || {};
    if (typeof window.s_pageview_id === 'undefined' || window.s_pageview_id === "") {
      this.pvid = this.utils.GenPVID();
      window.s_pageview_id = this.pvid;
    } else {
      this.pvid = window.s_pageview_id;
    }

    function assignKeys(parentObj) {
      const acceptedValues = [ "pageSegVars", "reqHeaders", "userSegVars", "webSegVars" ];
      for (let i in parentObj) {
        if (acceptedValues.includes(i)) Object.assign(TKeysObj, parentObj[i]);
        if (i == 'exclusionCategories' && parentObj[i] !== 'undefined' && typeof parentObj[i] !== 'undefined') {
          if (parentObj[i] == TKeysObj[0]) {
            delete(TKeysObj[0]);
          }
          if (typeof parentObj[i][0] !== 'undefined' && typeof parentObj[i][0].replace !== 'undefined') {
            TKeysObj['excl_cat'] = parentObj[i][0].replace(/ /g, '') || '';
          }
        }
      }
    }

    if (typeof PageMetadata !== "undefined") {
      assignKeys(PageMetadata);
    } else if (typeof AjaxTargetKeys !== "undefined") {
      assignKeys(AjaxTargetKeys);
    }

    // non virtual page
    TKeysObj.vp = '0';
    TKeysObj.uri = this.utils.sanitizeURI(window.location.pathname);
    TKeysObj.pvid = this.pvid;
    TKeysObj.cn = TKeysObj.cn || '0';
    TKeysObj.lif = sessionStorage.getItem('ibadsLif') == null ? 0 :  sessionStorage.getItem('ibadsLif');
    TKeysObj.actid = (PageMetadata.userSegVars && PageMetadata.userSegVars.actid) || '0';
    TKeysObj.xgdp = !ibAds.utils.GDPR_optin_check() ? '1' : '0';

    const filterObject = (obj, referenceKeys) => {
      Object.keys(obj).forEach((key) => {
        if (!referenceKeys.includes(key.toLowerCase())) {
          unMatchedKeys.push(key);
          delete obj[key];
        };
      });
    };

    if (TKeysObj.envp == 'qa') {
      TKeysObj.envp = 'qa01';
    }

    filterObject(TKeysObj, referenceKeys);
    console.warn('Unsupported target keys found: ', unMatchedKeys);
    return TKeysObj;
  },
  setExcList() {
    let excList = [];
    if (typeof PageMetadata !== "undefined" && typeof PageMetadata.exclusionCategories !== "undefined") {
      excList = PageMetadata.exclusionCategories;
    } else if (typeof AjaxTargetKeys !== "undefined" && typeof AjaxTargetKeys.exclusionCategories !== "undefined") {
      excList = AjaxTargetKeys.exclusionCategories;
    }
    if (!ibAds.utils.GDPR_optin_check()) {
      excList.push('limited');
    }
    if (typeof excList[0] !== 'undefined' && typeof excList[0].indexOf !== 'undefined') {
      // TO DO: Remove when clean exclusion targetists are provided
      if (excList[0].indexOf(',') !== -1 && excList.length === 1) {
        excList = excList[0].split(',').map(exclusion => exclusion.trim());
      }
      webmd.ads2.setPageExclusion(excList);
    }
  },
  setTargetKeys() {
    const targetKeys = {};
    webmd.ads2.setPageTarget(targetKeys);
  },
  adDisplay() {
    const self = this;
    const slotsAvailable = typeof window.adsPosSettings === 'undefined' ? {} : (typeof adsPosSettings.slotKeys === 'object' || self.altersComplete);
    const delay = slotsAvailable ? 0 : 2000;
    // for 3rd parties using profads, use data-attribute collection method to invoke defineAds() on div, instead of including an inline <script> tag
    new Promise((resolve, reject) => {
      setTimeout(() => {
        const dataAds = self.collectDataAds.call(self, slotsAvailable);
        if (dataAds) {
          resolve(dataAds);
        } else {
          reject(new Error('no dataAds available'));
        }
      }, delay);

    }).then((result) => {
      webmd.ads2.display();
      if (pageCurrentURL.indexOf('jim.fr') !== -1) {
        webmd.ads2.refresh();
      }
    }).catch((error) => {
      console.error('catch: no dataAds available', error);
    });
  },
  getASID(pos) {
    const self = this;

    // check if the pbr values are present
    let pbr = self.pbr;

    if (!pbr || !self.utils.isEmptyObject(pbr)) return null;

    // get the sizes of give pos and convert into string
    let sizes = webmd.ads2.sizes[pos];
    if (!sizes)
      return null;
    let strsizes = "";
    let len = sizes.length;
    for (let i = 0; i < len; i++) {
      strsizes += (typeof sizes[i] === 'string' ? sizes[i] : sizes[i].join("x"));
      if (i != len - 1) strsizes += ",";
    } // end for

    // check if the the size in the pbr matches this pos adslot sizes

    let spbr = {}; // slot level pbr object
    for (let i = 0; i < pbr.length; i++) {
      if (strsizes.indexOf(pbr[i].w + "x" + pbr[i].h) > -1) {
        spbr.a = pbr[i].a;
        spbr.pdp = pbr[i].pdp;
        spbr.pds = pbr[i].pds;
        spbr.pdi = pbr[i].pdi;
        self.ads2.spbr[pos] = spbr;
        //cache all values under each pos

        if (pbr.length <= 0) { self.pbr = null; }
        return spbr;
      } // end if
    } // end for
    return null; // return 0 if no matching size
  },
  getSlotVals(id) {
    if (!window.adsPosSettings) return;
    const slotKeys = window.adsPosSettings.slotKeys;
    if (typeof slotKeys === 'object') {
      let slotObj = slotKeys.filter(s => {
        return Object.keys(s)[0] === id;
      })[0];
      return typeof slotObj === 'undefined' ? undefined : slotObj[id] ;
    }
  },
  collectDataAds(slotVals) {
    const self = this;
    const ads = Array.from(document.querySelectorAll('[data-ad-unit="true"]'));

    ads.forEach(ad => {
      ad.classList.add('globalpromoadsunit');
      ad.classList.add('adUnit');
      const slotKeys = self.getSlotVals.call(self, ad.id) || {};
      webmd.ads2.defineAd({
        id: ad.id,
        pos: ad.dataset.adPos,
        keys: slotKeys
      });
    });
    return ads;
  },
  getSAF: function(data) {
    let cookieName = 'ads_saf';
    let cookieValue = data.saf;
    document.cookie = cookieName + "=" + cookieValue + ";domain=.mdalert.com;path=/";
    webmd.ads2.setPageTarget('saf', cookieValue);
  },
  brandAlertLauncherCB: function(eventData) {
    console.table('Starting Brandalert Launcher callback');
    const { type, payload } = eventData;
    if (type !== 'brandalert-launcher') return;
    const imgSrv = `img${this.utils.getEnvironment()}.medscapestatic.com`;
    const scriptConfig = {
      placement: document.body,
      id: 'brandalert_launcher',
      src: `//${imgSrv}/medscape-promo/brandalert_vue/4/dist/launcher/brandalert-launcher-session.js`,
      loadHandler: function() {
        window.BrandAlertLauncher.init(payload);
      }
    }
    this.utils.loadScript(scriptConfig);
    console.table('Ending Brandalert Launcher callback');
  },
  headlineBrandAlertCB(data) {
    const { actId, alertUrl } = data;
    const parentBody = window.top.document.body;
    const imgSrv = `img${this.utils.getEnvironment()}.medscapestatic.com`;
    const scriptConfig = {
      placement: parentBody,
      id: 'brandalert_launcher',
      src: `//${imgSrv}/medscape-promo/brandalert_vue/4/dist/launcher/brandalert-launcher-session.js`,
      extraAtributes: []
    }
    actId ? scriptConfig.extraAtributes.push({ 'data-ba-actid': actId }) : console.warn('ACT_ID not set on headline');
    alertUrl ? scriptConfig.extraAtributes.push({ 'data-ba-url': alertUrl }) : console.warn('alertUrl not set on headline');
    this.utils.loadScript(scriptConfig);
  },
  removeMEKeys: function(event) {
    var meArray = [ "rord", "mcs", "imid", "moa", "mop", "oaw" ]
    for (var i = 0;i < meArray.length;i++) {
      if (event.slot.getTargeting(meArray[i]).length == 1) {
        event.slot.clearTargeting(meArray[i])
      }
    }
  },
  postInstreamRecricWidgetSetUp: function(data) {
    let _adContainer = document.getElementById(data.slotId);//$("#"+data.slotId);
    let ad916containerMUK = document.querySelectorAll("[id^='ads-pos-916_']")[0];
    let ad917containerMUK = document.querySelectorAll("[id^='ads-pos-917_']")[0];
    let instreamText = document.getElementById('instream-recirc');
    let topSuggestedContainer = document.getElementById('top-recirc');
    let ad916container = document.getElementById('ads-pos-916');
    let ad917container = document.getElementById('ads-pos-917');

    this.utils.loadCSS("instreamrecircwidget");
    if (topSuggestedContainer == null) {

      instreamText.style = "visibility: visible; width: 100%; height: auto; border-top: 1px solid rgb(216, 216, 216); border-bottom: 1px solid rgb(216, 216, 216); padding: 24px 0px; margin: 9px 0px 24px;";

      // Create a "div" element:
      const topTextContainer = document.createElement("div");
      // Create a text node:
      let textNode = '';
      if (location.hostname.indexOf("jim.fr") !== -1) {
        textNode = document.createTextNode("Sélectionnés pour vous");
      } else if (location.hostname.indexOf("coliquio") !== -1) {
        textNode = document.createTextNode("Für Sie aus Medscape ausgewählt");
        instreamText.classList.add('coliquio-in-recirc');
      } else {
        textNode = document.createTextNode("Suggested for you");
      }
      // Append text node to newNode element:
      topTextContainer.setAttribute('id', 'top-recirc');
      topTextContainer.appendChild(textNode);

      // Insert before existing child:

      instreamText.insertBefore(topTextContainer, instreamText.children[0]);


      topTextContainer.style = "color:#313131;font-size:18px;padding-bottom: 12px;text-transform: uppercase;line-height: 22px;font-family: 'Proxima Nova';font-weight: 700;";
      if (location.hostname.indexOf('univadis') !== -1) {
        topTextContainer.style.color = '#232323';
        topTextContainer.style.fontFamily = "Source Sans Pro";
        instreamText.style.marginTop = '15px';
        instreamText.style.marginBottom = '60px';
      }

      if (window.ibAds.utils.getBrkPnt() === 1) {
        //instreamText.style = "margin: '1px 0px 16px 0px';padding:'16px 0px',font-size:16px";
        instreamText.style.margin = '1px 0px 16px 0px';
        instreamText.style.padding = '16px 0px';
        instreamText.style.fontSize = '16px';
        topTextContainer.style.fontSize  = "16px";
        if (location.hostname.indexOf('univadis') !== -1) {
          topTextContainer.style.paddingBottom = '8px';
          instreamText.style.padding = '20px 0px';
          instreamText.style.marginTop = "22px";
          instreamText.style.marginBottom = '30px';
        }
        if (data.type.toLowerCase() === "bulletpoint" && location.hostname.indexOf("jim.fr") !== -1) {
          topTextContainer.style.paddingBottom  = "8px";
        }
      }

      if (location.hostname.indexOf("coliquio") !== -1) {
        instreamText.style = "";
        topTextContainer.style = "";
      }

    }

    _adContainer.classList.add("active"); //addClass('active');


    if (ad916containerMUK !== undefined && ad917containerMUK !== undefined && ad916containerMUK.classList.contains('active') && ad917containerMUK.classList.contains('active')) {
      if (data.type.toLowerCase() !== "bulletpoint" && location.hostname.indexOf('jim.fr') !== -1) {
        ad916containerMUK.style.marginBottom  = "16px";
      } else {
        ad916containerMUK.style.marginBottom  = "8px";
      }
      if (window.ibAds.utils.getBrkPnt() === 1) {
        if (data.type.toLowerCase() === "bulletpoint" &&  location.hostname.indexOf('jim.fr') !== -1) {
          ad916containerMUK.style.marginBottom  = "16px";
        }
      }
    }
    if (ad916container !== null && ad917container !== null && ad916container.classList.contains('active') && ad917container.classList.contains('active')) {
      ad916container.style.marginBottom  = "8px";
      if (location.hostname.indexOf("coliquio") !== -1) {
        ad916container.classList.add("coliquio-ads-container");
      }
    }
  },
  load3rdPartyModules: function() {
    window.ibAds.thirdPartyModules.forEach(m => {
      let mod = m.init.call(m);
      // test if module returns a promise, assign to profads, currently only used for lotame
      if (typeof mod === 'object' && mod.hasOwnProperty('promise')) window.ibAds[m.name] = mod;
      if (m.callBack) webmd.tpsvc.cmds.push(m.callBack.bind(m));
    });
  },
  /*
    * Display Manufacturers Announcment Layer
    * */
  displayMANLayer: function(adNode) {
    // DO NOT load MAN Layer on landscape orientation in mobile
    // if (this.DetectUA({}).isMobile && this.utils.getScreenOrientation() === 'landscape') return;

    if (window.displayManLayerCalled) {
      return;
    }
    window.displayManLayerCalled = true;
    const self = this;
    self.utils = utils;
    const originContainer = document.getElementById('man-layer-id');
    const bodyClass = 'man-layer-visible';
    const closeClass = 'man-close';
    const body = document.body.classList.add(bodyClass);
    const manContent = adNode;
    manContent.classList.add('man-content');

    const whiteOutLayer = self.utils.createElement({
      name: 'div',
      className: 'man-whiteout-layer'
    });
    self.whiteOutLayer = whiteOutLayer;
    const manHeader = self.utils.createElement({
      name: 'div',
      className: 'man-header',
      children: [
        {
          name: 'span',
          className: 'man-ifi',
          text: 'Information from Industry'
        },
        {
          name: 'span',
          className: 'man-header-title',
          text: 'Manufacturer Announcement'
        },
        {
          name: 'span',
          className: closeClass,
          text: 'close'
        }
      ]
    });
    const manLayer = self.utils.createElement({
      name: 'div',
      className: 'man-ad-layer',
      id: 'man-layer'
    })
    manLayer.append(manHeader);
    manLayer.append(manContent);

    document.body.append(whiteOutLayer)
    document.body.append(manLayer);
    addCloseHandler(closeClass);

    function addCloseHandler(closeClass) {
      document.querySelector(`.${closeClass}`).addEventListener('click',  closeMAN);
    }

    function closeMAN(e) {
      // do not close if on different page than the creative
      if (e.target.classList.contains('MAN_CTA_CLOSE')) {
        const url = e.target.dataset.url ? e.target.dataset.url : e.target.href;
        const articleID = PageMetadata.pageSegVars.art.replace('drg', '');
        if (url.indexOf(articleID) === -1) {
          window.open(url);
          return;
        }
      }
      whiteOutLayer.style.display = 'none';
      manLayer.remove();
      if (originContainer) {
        originContainer.remove();
      }
      document.body.classList.remove(bodyClass);
      if (window.wmdPageLink && typeof window.wmdPageLink === 'function') {wmdPageLink('man_dsms', e.target)};
    }

    return manLayer;
  },
  adTracking: {
    track: function(string, url) {
      wmdPageLink(string);
    },
    extLink: function(string, url) {
      wmdSetContext('wb.exiturl', 'exit::' + url);
      wmdPageLink(string);
      wmdRemContext('wb.exiturl');
    },
    intLink: function(string, url) {
      wmdSetContext('wb.cstm', 'landpg::' + url);
      wmdTrack(string);
      wmdRemContext('wb.cstm');
    },
    isInternalExternal: function(url) {
      const urlArray = url.split('/');
      let destination;
      if (urlArray.indexOf('medscape') !== -1 || urlArray[0].charAt[0] === '/') {
        destination = 'internal';
      } else {
        destination = 'external'
      }
      return destination;
    }
  },
  /*
    Interscroller functionality
    */
  interscrollerInit: function(data) {
    const that = this;
    that.loadIntescrlCSS();
    const interscroller = document.getElementById(data.slotId);
    const interscrollerIframe = interscroller.querySelector('iframe');

    interscroller.classList.add('interscroller');
    interscrollerIframe.width = "100%";

    if (interscroller.parentNode.classList.contains('ad-item')) {
      interscroller.parentNode.classList.remove('ad-item');
      interscroller.parentNode.style.width = '100%';
    }
    // video player disable
    if (location.href.includes('mdalert' && typeof jwplayer === 'function')) {
      jwplayer('mdavPlayer').setFloating(false);
    }
  },
  loadIntescrlCSS() {
    const that = this;
    let path = `//img${that.utils.getEnvironment()}.medscapestatic.com/medscape-core/ads/css/layers/ibads-interscroller.css`;
    let css = that.utils.createElement({
      name: 'link',
      attributes: [
        { rel: 'stylesheet' },
        { href: path },
        { type: 'text/css' },
        { media: 'screen' }
      ],
      eventHandler: {
        eventName: 'load',
        fn() {
          console.log('interscroller css loaded');
        }
      }
    });
    that.utils.appendExternalNode(css, document.head);
    return css;
  },
  getGeoLocation(data = {}, source = "") {
    const self = this;
    let geo, ous;
    const geoLocations = [
      "AD", "AL", "AN", "AT", "AX", "AZ", "BA",
      "BE", "BG", "BV", "BY", "CH", "CY", "CZ",
      "DE", "DK", "EE", "ES", "FI", "FO", "FR",
      "GB", "GE", "GG", "GI", "GL", "GR", "HR",
      "HU", "IC", "IE", "IM", "IS", "IT", "JE",
      "LI", "LT", "LU", "LV", "MC", "MD", "ME",
      "MQ", "MT", "NL", "NO", "PL", "PT", "RO",
      "RS", "RU", "SE", "SI", "SJ", "SK", "SM",
      "UA", "VA", "XK"
    ];

    if (ibAds.utils.getParam('geo') !== "") {
      geo = ibAds.utils.getParam('geo');
      ous = true;
    } else {
      geo = window.PageMetadata.reqHeaders.geoc;
      ous = geoLocations.includes(geo);
    }
    if (data.slotId) {
      const iframe = document.querySelector(`#${data.slotId} iframe`);
      if (iframe) iframe.contentWindow.postMessage(JSON.stringify({ "geo": geo, "ous": ous }), "*");
    }
    return { OUS: ous, geo: geo };
  },

  addParentStyleHeadlineColiquio: function(data) {
    let containerDivId = data.slotId;
    let containerBorder = data.borderRadius;
    let containerShadow = data.shadow;
    let containerElement = document.getElementById(containerDivId);
    if (containerElement) {
      containerElement.style.borderRadius = containerBorder;
      containerElement.style.boxShadow = containerShadow;
      containerElement.classList.add('adlabelblank');
    }
  },
  getPPID() {
    const ppidExists = document.cookie.match(/ppid=[a-zA-Z0-9]+/);
    const ppidValue = ppidExists ? ppidExists[0].split('=')[1] : ibAds.setPPID();
    if (ppidValue) {
      webmd.ads2.setPublisherProvidedId(ppidValue);
    }
  },
  setPPID() {
    function setPPIDExpiry() {
      let now = new Date(); // current date
      let currentTime = now.getTime();//in millisecs
      let expireTime = currentTime + 180 * 24 * 60 * 60 * 1000; // expire after 180 days
      now.setTime(expireTime);
      return now.toUTCString();
    }
    function generatePPID(length = 42) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      let timestamp = Math.round(Date.now() / 1000);
      return result + timestamp;
    }

    const ppidValue = generatePPID();
    let domain = window.location.host.split('.').slice(-2).join('.');
    if (domain == 'co.uk') {
      domain = window.location.host.split('.').slice(-3).join('.');
    }
    document.cookie = `ppid=${ppidValue};expires=${setPPIDExpiry()};domain=.${domain};path=/;secure`;
    return ppidValue;
  },
  displayAdhesiveFooter(e) {
    const that = this;
    that.utils.loadScript({
      src: `//img${that.utils.getEnvironment()}.medscapestatic.com/medscape-core/ads/js/dfp/external/ibads-adhesive-footer.js`,
      loadHandler() { that.initAdhesiveFooter(e) },
      errorHandler() {  debug.error('[ibads] Adhesive footer script failed to load.'); },
      async: true,
      cors: true
    });
    return that;
  },

};

