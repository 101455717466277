
export default {

  /**
     * Get a property of an object via dot-delimited name string, and optionally
     * create the property and any ancestor properties that do not already exist.
     *
     * @param {String} name Dot-delimited string representing a property name, for
     * example: 'document', 'location.href', 'window.open' or 'foo.bar.baz'.
     * @param {Boolean} [create=false] Create final and intermediate properties if they don't
     * exist.
     * @param {Object} [context=window] Optional context in which to evaluate name.
     * @returns {Object} An object reference or value on success, otherwise undefined.
     *
     * @example
     * var o = webmd.object.get('webmd.m.myObject.data.part');
     * if (o) { do_something(); }
     *
     * @example
     * var o = webmd.object.get('webmd.m.myObject.data.part', true);
     * // o is guaranteed to exist because we specified the "create" option
     *
     * @example
     * // Search within the context of a known object
     * var o = webmd.object.get('myObject.data.part', webmd.m);
     *
     */
  get: function(parts, create, obj) {
    var origParts = parts;

    if (typeof parts === 'string') {
      parts = parts.split('.');
    }

    if (typeof create !== 'boolean') {
      obj = create;
      create = undefined;
    }

    obj = obj || window;
    var p;

    //first, check if the value exists as a string i.e. obj['a.b']
    if (obj[origParts] !== undefined) {
      obj = obj[origParts];
    } else {
      //otherwise, iterate through the split parts

      while (obj && parts.length) {
        p = parts.shift();
        if (obj[p] === undefined && create) {
          obj[p] = {};
        }
        obj = obj[p];
      }
    }

    return obj;
  },


  /**
     * Set a property of an object via dot-delimited name string, creating any
     * ancestor properties that do not already exist.
     *
     * @param {String} name Dot-delimited string representing a property name, for
     * example: 'document', 'location.href', 'window.open' or 'foo.bar.baz'.
     * @param {expression} value Any valid JavaScript expression.
     * @param {Object} [context=window] Optional context in which to evaluate name.
     *
     * @returns {expression} The value if set successfully, otherwise undefined.
     */
  set: function(name, value, context) {

    var parts = name.split('.'),
      prop = parts.pop(),
      obj = this.get(parts, true, context);

    // Only return the value if it is set successfully.
    return (obj && typeof obj === 'object' && prop) ? (obj[prop] = value) : undefined;
  },


  /**
     * Using dot-delimited name string, return whether a property of an object
     * exists.
     *
     * @param {String} name Dot-delimited string representing a property name, for
     * example: 'document', 'location.href', 'window.open' or 'foo.bar.baz'.
     * @param {Object} [context=window] Optional context in which to evaluate name.
     *
     * @returns {Boolean} Whether or not the property exists.
     */
  exists: function(name, context) {
    return this.get(name, context) !== undefined;
  }

  /*! END LICENCE getObject */

};

