import stopWords from './IbadsGlobals.js';

function ibadsDynamicTargetKeys(k, v) {
  //tug key
  if (typeof networkGate !== "undefined" && networkGate !== null) {
    let tug =
      typeof networkGate.bundleId !== "undefined" && networkGate.bundleId != ""
        ? networkGate.bundleId.toString()
        : "0";
    webmd.ads2.setPageTarget("tug", tug);
  }
  // HUID key
  const huid = window.PageMetadata && PageMetadata.userSegVars && PageMetadata.userSegVars.huid
    ? String(PageMetadata.userSegVars.huid).trim()
    : "0";
  webmd.ads2.setPageTarget('huid', huid !== '' ? huid : "0");
  //Set ACS value from PageMetadata
  if (
    typeof PageMetadata === "object" &&
    typeof PageMetadata.authVar === "object"
  ) {
    webmd.ads2.setPageTarget(
      "acs",
      `${PageMetadata.authVar.authenticationChannel}-${PageMetadata.authVar.authLevel}`
    );
  } else {
    webmd.ads2.setPageTarget("acs", "0");
  }

  //urlkw key logic
  function getUrlKw(countryDomain) {
    const urlkeywords = [];
    const numsRegex = /^\d+/;
    const exludeList = [
      "viewarticle",
      "s",
      "artikelansicht",
      "voirarticle",
      "verarticulo",
      "verartigo",
    ];
    const urlKeys = window.location.pathname
      .split("/")
      .filter(Boolean)
      .filter((ele) => !exludeList.includes(ele.toLowerCase()));
    urlKeys.forEach((key) => {
      let decodedKey = decodeURIComponent(key);
      if (decodedKey.indexOf("-") > -1) {
        urlkeywords.push(
          ...decodedKey
            .split("-")
            .filter(
              (ele) =>
                !numsRegex.test(ele) &&
                !stopWords[countryDomain].has(ele.toLowerCase())
            )
        );
      } else {
        if (
          numsRegex.test(decodedKey) &&
          !stopWords[countryDomain].has(decodedKey.toLowerCase())
        ) {
          urlkeywords.push(decodedKey);
        }
      }
    });
    return urlkeywords;
  }
  if (window._isAnArticle) {
    const domainRegex = /\.(it|es|de|fr)/;
    const countryDomain = window.location.origin.match(domainRegex)
      ? window.location.origin.match(domainRegex)[1]
      : "en";
    const urlkw = getUrlKw(countryDomain);
    webmd.ads2.setPageTarget("urlkw", urlkw);
  }
}

export { ibadsDynamicTargetKeys as default };

